/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import SolicitacaoArquivos from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';
import Historico from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/Historico';
import CurrencyInput from 'react-currency-input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { ENVIAR_EVIDENCIA } from 'services/SolicitacaoFluxo';

import { GET_SOLICITACAO } from 'services/Solicitacao';

const ContentEnviarEvidencia = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [solicitacaoDados, setSolicitacaoDados] = useState({});
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});
  const [textError, setTextError] = useState('');

  //======================================================================================================================
  useEffect(() => {
    (async () => {
      showLoader(true);
      const response = await GET_SOLICITACAO(solicitacaoId);
      if (response?.value) {
        setSolicitacaoDados(response.value?.solicitacaoDados);
      }

      const responseModel = { value: { solicitacaoId: solicitacaoId } }; //await GET_ENVIAR_COMPROVANTE_MODEL(solicitacaoId);

      setModel(responseModel?.value || {});

      showLoader(false);
    })();
  }, []);

  function Validacao(model) {
    return Boolean(
      model.outrosResultados &&
        model.volumeSellOutRealizado &&
        model.observacao &&
        model.files
    );
  }

  function enviarEvidencia() {
    showLoader(true);

    const validacao = Validacao(model);
    // console.log(validacao);
    if (validacao) {
      (async () => {
        //alert(model.observacao);
        const response = await ENVIAR_EVIDENCIA(model);

        if (response.value) {
          setModel({});
        }
        setResultModel(response);
        setTextError('');
        window.scrollTo(0, 0);
      })();
    } else {
      setTextError(
        'Preencha todos os campos do formulário para realizar o envio.'
      );
    }
    showLoader(false);
  }

  //======================================================================================================================
  // Renders

  // Erros ---------------------------------------------------------------------------------------------------------------
  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  // Sucesso -------------------------------------------------------------------------------------------------------------
  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  // Botao Resumo --------------------------------------------------------------------------------------------------------
  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  // Arquivos ------------------------------------------------------------------------------------------------------------
  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />;
  }

  // Anexo Evidencia ----------------------------------------------------------------------------------------------------
  function renderEnviarEvidencia() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <label>Objetivo da Ação:</label>
        <h6>{solicitacaoDados?.objetivoAcao}</h6>
        <hr />
        <h5 className="text-danger">{textError && textError}</h5>
        <div className="row mb-3">
          <div className="col-lg-9">
            <label htmlFor="sellout" className="ml-0">
              Resultados Alcançados (Sell Out Realizado): *
            </label>
            {/* <CurrencyInput
              decimalSeparator=","
              thousandSeparator=""
              className={`form-control `}
              value={model.volumeSellOutRealizado || ''}
              onChange={(value) => {
                setModel({ ...model, volumeSellOutRealizado: value });
              }}
            /> */}
            <input
              type="number"
              className={`form-control`}
              value={model.volumeSellOutRealizado || ''}
              onChange={(value) => {
                setModel({ ...model, volumeSellOutRealizado: value });
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-9">
            <label htmlFor="outrosResultados" className="ml-0">
              Outros Resultados: *
            </label>
            <textarea
              className="form-control"
              id="outrosResultados"
              name="outrosResultados"
              rows="4"
              placeholder="Outros resultados (Cliques, engajamento, views, etc):"
              onChange={(ev) => {
                setModel({ ...model, outrosResultados: ev.target.value });
              }}
            ></textarea>
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-4">
                <label htmlFor="cliques" className="ml-0">
                  Cliques
                </label>
                <textarea
                  className="form-control"
                  id="cliques"
                  name="cliques"
                  rows="2"
                  placeholder="Cliques"
                  onChange={(ev) => {
                    setModel({ ...model, cliques: ev.target.value });
                  }}
                ></textarea>
              </div>
              <div className="col-4">
                <label htmlFor="engajamento" className="ml-0">
                  Engajamento
                </label>
                <textarea
                  className="form-control"
                  id="engajamento"
                  name="engajamento"
                  rows="2"
                  placeholder="Engajamento"
                  onChange={(ev) => {
                    setModel({ ...model, engajamento: ev.target.value });
                  }}
                ></textarea>
              </div>
              <div className="col-4">
                <label htmlFor="views" className="ml-0">
                  Views
                </label>
                <textarea
                  className="form-control"
                  id="views"
                  name="views"
                  rows="2"
                  placeholder="views"
                  onChange={(ev) => {
                    setModel({ ...model, views: ev.target.value });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-9">
            <label htmlFor="outrosResultados" className="ml-0">
              Comentários Adicionais: *
            </label>
            <textarea
              className="form-control"
              id="observacao"
              name="observacao"
              rows="4"
              placeholder="Digite aqui comentários adicionais."
              onChange={(ev) => {
                setModel({ ...model, observacao: ev.target.value });
              }}
            ></textarea>
          </div>
          <div className="col-lg-3 mt-2">
            <div className="mt-4 mb-3">
              <label type="button" className="btn btn-primary btn-block mt-3">
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => {
                    setModel({
                      ...model,
                      files: [...(model.files || []), ...e.target.files],
                    });
                    e.target.value = null;
                  }}
                />
                <FontAwesomeIcon icon={faPlus} /> Adicionar Evidência
              </label>
            </div>

            {(model.files || []).map((file, index) => (
              <div key={index} className="mb-2">
                {file.name}{' '}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    model.files.splice(index, 1);
                    setModel({ ...model });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ))}

            <button
              className="btn btn-primary btn-block"
              onClick={enviarEvidencia}
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  // Historico -----------------------------------------------------------------------------------------------------------
  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  // Modal Resumo --------------------------------------------------------------------------------------------------------
  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  // Render Principal ----------------------------------------------------------------------------------------------------
  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model && model.solicitacaoId && model.solicitacaoId > 0
            ? ' - Arte final enviada, aguardando evidência.'
            : '')
        }
      />
      <div className="content-solicitacaofluxo text-center">
        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivos()}

        {renderEnviarEvidencia()}

        {renderHistory()}

        {renderModalSummary()}
      </div>
    </div>
  );
};

export default ContentEnviarEvidencia;
