import React from 'react';

import './styles.scss';
import BrandColor from 'assets/imgs/logo_cliente.png';
import BrandWhite from 'assets/imgs/logo-branca.png';

const Brand = (props) => {
  const { white } = props;
  return (
    <img className="brand" src={white ? BrandWhite : BrandColor} alt="Brf" />
  );
};

export default Brand;
