/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import SingleSelect from 'components/_base/SelectSingle';
import { Context } from 'context/AuthContext';

import { UPDATE_MEUS_DADOS, GET_USUARIO } from 'services/Usuario';

import './styles.scss';

const FormDataUser = () => {
  const { getRegionalSelectListFilhas, showLoader } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_epson_site'));

  const [dadosDoUsuario, setdadosDoUsuario] = useState({});
  const [regionalList, setRegionalList] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    showLoader(true);
    const data = await UPDATE_MEUS_DADOS(dadosDoUsuario);

    setShowSuccess(false);
    setErrorMessage('');

    if (data.value === false && data.errors && data.errors.length > 0) {
      setErrorMessage(data.errors[0]);
    } else if (data.value === true) {
      setShowSuccess(true);
    } else {
      setErrorMessage('Não foi possível executar esta operação!');
    }
    showLoader(false);
  };

  // eslint-disable-next-line no-unused-vars
  const getRegionalId = (value) => {
    setdadosDoUsuario({ ...dadosDoUsuario, regionalId: value });
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      setRegionalList(await getRegionalSelectListFilhas());

      const data = await GET_USUARIO(userAuth.userId);
      setdadosDoUsuario({
        usuarioId: data.value.usuarioId,
        nome: data.value.nome,
        email: data.value.email,
        telefone: data.value.telefone,
      });
      showLoader(false);
    })();
  }, []);

  async function onChangeWithName(value, name) {
    setdadosDoUsuario({
      ...dadosDoUsuario,
      [name]: value,
    });
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setdadosDoUsuario({
      ...dadosDoUsuario,
      [name]: value,
    });
  }

  return (
    <div>
      <div className="title">DADOS</div>
      <div className="info">
        <form>
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage('')}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          {showSuccess && (
            <Alert
              variant="success"
              onClose={() => setShowSuccess(false)}
              dismissible
            >
              Dados alterados com sucesso!
            </Alert>
          )}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Nome
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="nome"
                onChange={onChangeInput}
                value={dadosDoUsuario.nome}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              E-mail
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={onChangeInput}
                value={dadosDoUsuario.email}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Telefone
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="telefone"
                onChange={onChangeInput}
                value={dadosDoUsuario.telefone}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="offset-md-4 col-md-4">
              <Button
                onClick={(e) => onSubmit(e)}
                className="query-button"
                variant="primary"
                type="submit"
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </Button>
            </div>
          </FormGroup>
        </form>
      </div>
    </div>
  );
};

export default FormDataUser;
