import React, { useEffect, useState, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChartBar } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [, setCorRascunho] = useState([]);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper-choose-the-costumer">
        <div className="container-fluid">
          <div className="row pt-5 px-5 mx-auto">
            <div className="offset-md-1 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <Carousel>
                {warnings ? (
                  warnings.map((item) => {
                    return (
                      <Carousel.Item key={item.avisoId}>
                        {item.urlRedirecionamento ? (
                          <a
                            href={item.urlRedirecionamento}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="d-block w-100"
                              src={item.urlImagem}
                              alt={item.texto}
                            />
                          </a>
                        ) : (
                          <img
                            className="d-block w-100"
                            src={item.urlImagem}
                            alt={item.texto}
                          />
                        )}
                      </Carousel.Item>
                    );
                  })
                ) : (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="https://autoparts-warehouse.co.za/wp-content/uploads/2022/02/0-test.jpg"
                      alt="{item.texto}"
                    />
                  </Carousel.Item>
                )}
              </Carousel>
            </div>
            <div className="col-md-4">
              <div className="actions row">
                <div className="col-md-12 col-sm-6">
                  <Link to="/menu-solicitacoes">
                    <div>
                      <div className="button-content">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                          </svg>
                        </div>
                        <div className="btn-divider" />
                        <div>Solicitações</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-12 col-sm-6">
                  <Link to="/menu-consultas">
                    <div>
                      <div className="button-content">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L486.6 441.4 509.3 464 464 509.3l-22.6-22.6L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" />
                          </svg>
                        </div>
                        <div className="btn-divider" />
                        <div>Consultas</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-md-12 col-sm-6">
                  <Link to="/pendencias">
                    <div
                      style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}
                    >
                      <div className="button-content">
                        <div
                          style={{
                            color: corPendencia > 0 ? 'white' : '',
                          }}
                        >
                          <FontAwesomeIcon icon={faBell} size="3x" />
                        </div>
                        <div
                          className="btn-divider"
                          style={{
                            marginLeft: '10px',
                            backgroundColor: corPendencia > 0 ? 'white' : '',
                          }}
                        />
                        <div
                          style={{
                            color: corPendencia > 0 ? 'white' : '',
                          }}
                        >
                          pendência
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-12 col-sm-6">
                  <Link to="/relatorios">
                    <div>
                      <div className="button-content">
                        <div>
                          <FontAwesomeIcon icon={faChartBar} size="3x" />
                        </div>
                        <div className="btn-divider" />
                        <div>Relatório</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center text-center align-items-end atendimento-home pt-5">
            <h5 className="font-weight-bold home-horario-atendimento">
              Em caso de dúvidas ou ocorrências sobre o Mídias Epson, ligar para
              (11) 94519-0299 de segunda a sexta no horário das 9:00 às 18:00
              ou, clique em atendimento ao usuário e abra um chamado que
              entraremos em contato.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
