import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';

import './styles.scss';

const Notifications = () => {
  const [pendencias, setPendencias] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setPendencias(data.value);
    })();
  }, []);

  return (
    <>
      {/* <Link to="/consulta" className="notifications cursor-pointer">
        <FontAwesomeIcon icon={faSearch} size="2x" />
      </Link> */}
      <a href="/pendencias" className="notifications cursor-pointer">
        <FontAwesomeIcon icon={faBell} size="2x" />
        {pendencias > 0 ? <span id="pendencias">{pendencias}</span> : ''}
      </a>
    </>
  );
};

export default Notifications;
