/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';

import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';
import ButtonMaterial from './ButtonMaterial';
import Solicitacao from './Solicitacao';

import { tabs } from '../_constants';
import './styles.scss';

const Solicitacoes = (props) => {
  const history = useHistory();
  const location = useLocation();

  let {
    model,
    baseUrl,
    updateRascunho,
    entregaLojasParticipantes,
    entregaOutro,
    entities,
    clienteLayouts,
    renderErrors,
    setActiveTab,
  } = props;
  let materiaisCadastroItens = entities.materiaisCadastroItens;
  let entregaCentroDistribuicao = model.loja?.enderecoFilial;
  model.solicitacoes = model.solicitacoes || [];

  let materiais = [].concat(
    ...(entities.tipoMateriais || []).map(
      (tipoMaterial) => tipoMaterial.materiaisComunicacao
    )
  );

  let loja = model.loja || {};

  let urlSolicitacoes = `${baseUrl}/${model.rascunhoId}/${tabs.tabSolicitacoes}`;

  const initialIndex = 0;
  const currentIndex =
    parseInt(location.pathname.split('/')[4] ?? '0') || initialIndex;
  const [activeSolicitacaoIndex, setActiveSolicitacaoIndex] =
    useState(currentIndex);

  const changeMaterial = (index) => {
    history.push(`${urlSolicitacoes}/${index}`);
  };

  const proximoMaterial = () => {
    var index = activeSolicitacaoIndex + 1;
    if (index >= model.solicitacoes.length) {
      setActiveTab(tabs.tabResumo);
    } else {
      changeMaterial(index);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      let index =
        parseInt(location.pathname.split('/')[4] ?? '0') || initialIndex;
      setActiveSolicitacaoIndex(index);
    });
  }, [history]);

  const renderSideBar = () => {
    if (!model.solicitacoes || !model.solicitacoes.length) return null;

    if (!materiais || !materiais.length) return null;

    let buttonSidebarMaterial = model.solicitacoes
      .map((solicitacao) => {
        let material = materiais.find(
          (m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId
        );
        return {
          solicitacao: solicitacao,
          pedidoDeCampanha: model.pedidoDeCampanha,
          entities: entities,
          material: material,
        };
      })
      .sort((a, b) => a.material.nome.localeCompare(b.material.nome));

    let ButtonMaterialSidebar = buttonSidebarMaterial.map((value, index) => {
      return (
        <ButtonMaterial
          key={index}
          solicitacao={value.solicitacao}
          pedidoDeCampanha={value.pedidoDeCampanha}
          entities={value.entities}
          material={value.material}
          active={index === activeSolicitacaoIndex}
          onClick={() => {
            changeMaterial(index);
          }}
        />
      );
    });

    return (
      <div className="solicitacao-panel">
        <div className="solicitacao-panel-header">MATERIAIS</div>

        <div className="solicitacao-panel-body">{ButtonMaterialSidebar}</div>
      </div>
    );
  };

  const renderSolicitacao = () => {
    if (!model.solicitacoes || !model.solicitacoes.length) return null;

    if (!materiais || !materiais.length) return null;

    if (!materiaisCadastroItens || !materiaisCadastroItens.length) return null;

    let solicitacao = model.solicitacoes[activeSolicitacaoIndex];
    if (!solicitacao) return null;

    let materialComunicacao = model.solicitacoes.sort((a, b) =>
      a.materialComunicacao.nome.localeCompare(b.materialComunicacao.nome)
    );
    materialComunicacao = materiais.find(
      (m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId
    );

    return (
      <div className="solicitacao-panel">
        <div className="solicitacao-panel-header">
          {materialComunicacao.nome}
        </div>

        <div className="solicitacao-panel-body">
          <Solicitacao
            tipoNegocioId={model.tipoNegocioId}
            pedidoDeCampanha={model.pedidoDeCampanha}
            updateRascunho={updateRascunho}
            pedidoId={model.rascunhoId}
            loja={loja}
            entregaCentroDistribuicao={entregaCentroDistribuicao}
            entregaLojasParticipantes={entregaLojasParticipantes}
            entregaOutro={entregaOutro}
            entities={entities}
            clienteLayouts={clienteLayouts}
            solicitacao={solicitacao}
            proximoMaterial={proximoMaterial}
            renderErrors={renderErrors}
            solicitacaoIndex={activeSolicitacaoIndex}
            urlSolicitacao={`${urlSolicitacoes}/${activeSolicitacaoIndex}`}
            locationIndexSolicitacaoTab={5}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper-content-pedido-solicitacoes">
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Preencha os dados" />

      <div className="content-pedido-solicitacoes">
        <div className="row">
          <div className="col-lg-3 mb-3">{renderSideBar()}</div>

          <div className="col-lg-9">{renderSolicitacao()}</div>
        </div>

        <div className="buttons-navigation">
          <button
            style={{ width: '150px' }}
            className="  btn btn-primary"
            onClick={() => {
              updateRascunho();
              setActiveTab(tabs.tabEntrega);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Solicitacoes;
