import React from 'react';
import './styles.scss';
import DropDownFiltro from './DropDownFiltro';

const Filtro = (props) => {
  const {
    setFetchDataTrigger,
    filtro,
    filtroDisp,
    filtroSelecionado,
    setFiltroSelecionado,
  } = props;

  return (
    <div className="wrapper-filtro">
      <div className="filtro">
        <p>Ano</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="ano"
          filtro={filtro?.ano}
          filtroDisp={filtroDisp?.ano}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div>
      <div className="filtro">
        <p>Mês</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="mes"
          filtro={filtro?.mes}
          filtroDisp={filtroDisp?.mes}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div>
      {/* <div className="filtro">
        <p>Regional</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="regional"
          filtro={filtro?.regional}
          filtroDisp={filtroDisp?.regional}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div> */}
      {/* <div className="filtro">
        <p>Canal</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="canal"
          filtro={filtro?.canal}
          filtroDisp={filtroDisp?.canal}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div> */}
      {/* <div className="filtro">
        <p>Atividade</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="atividade"
          filtro={filtro?.atividade}
          filtroDisp={filtroDisp?.atividade}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div> */}
      <div className="filtro">
        <p>Categoria</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="categoria"
          filtro={filtro?.categoria}
          filtroDisp={filtroDisp?.categoria}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div>
      {/* <div className="filtro">
        <p>Programa BRF</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="programa"
          filtro={filtro?.programa}
          filtroDisp={filtroDisp?.programa}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div> */}
      <div className="filtro">
        <p>Marca</p>
        <DropDownFiltro
          setFetchDataTrigger={setFetchDataTrigger}
          nome="marca"
          filtro={filtro?.marca}
          filtroDisp={filtroDisp?.marca}
          selecao={filtroSelecionado}
          setSelecao={setFiltroSelecionado}
        />
      </div>
    </div>
  );
};

export default Filtro;
