import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function CustomRoute({ isPrivate, ...rest }) {
  const userAuth = JSON.parse(localStorage.getItem('midias_epson_site'));

  // Validação caso o usuario precisa atualizar os dados cadastrais
  if (
    rest.path === '/atualizar-dados' &&
    userAuth &&
    userAuth.atualizarDados === false
  ) {
    return <Redirect to="/" />;
  }

  if (
    userAuth &&
    userAuth.atualizarDados === true &&
    rest.path !== '/atualizar-dados'
  ) {
    return <Redirect to="/atualizar-dados" />;
  }

  if (isPrivate === true && (!userAuth || userAuth.authenticated === false)) {
    return <Redirect to={`/login?urlReturn=${rest.path}`} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} />;
}

export default CustomRoute;
