/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Bottom from 'components/Bottom';

import Brand from 'components/_base/Brand';

import { Form, Button, Badge, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import { Context } from 'context/AuthContext';
import ContentLogin from './ContentLogin';

import './styles.scss';

const LoginOkta = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const params = new URLSearchParams(useLocation().search);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const initialState = {
    redirect: params.get('urlReturn')
      ? decodeURIComponent(params.get('urlReturn'))
      : '/',
  };

  const { handleSSOLogin } = useContext(Context);

  async function onSubmit(e) {
    e.preventDefault();
    await oktaAuth.signInWithRedirect();
  }

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    (async () => {
      const urlLogout = params.has('logout');
      console.log(urlLogout);
      if (urlLogout) {
        await oktaAuth.signOut({
          revokeAccessToken: false,
          revokeRefreshToken: false,
        });
      }

      if (authState?.isAuthenticated) {
        const tokenManager = oktaAuth.tokenManager;
        const accessToken = await tokenManager.get('accessToken');
        const idToken = await tokenManager.get('idToken');
        // All user info
        const userInfo = await oktaAuth.token.getUserInfo(accessToken, idToken);
        // Realizar o login na aplicação e redirecionar para a home.
        console.log(userInfo.email);
        const data = await handleSSOLogin({
          nomeLogin: userInfo.email,
          token: '',
        });
        // Modal de email não cadastrado
        if (data.code === 200 && !data.value.authenticated) {
          setShowModal(true);
          setError(data.value.error);
        }
      }
    })();
  }, []);

  return (
    <>
      <header className="header-login">
        <div className="content-brand">
          <Brand white />
        </div>
        <div className="content-form-login">
          <div style={{ marginTop: '13px' }}>
            <label htmlFor="check">
              <FontAwesomeIcon icon={faBars} size="2x" />
            </label>

            <input type="checkbox" id="check" />

            <label htmlFor="check">
              <div className="turn-off-the-light" />
            </label>
            <Form onSubmit={(e) => onSubmit(e)}>
              <div className="form-login">
                <div>
                  <label htmlFor="check">
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                  </label>
                </div>
                <div>
                  <button
                    onClick={() => onSubmit}
                    type="submit"
                    className="btn bg-white text-primary"
                  >
                    Entrar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </header>
      <ContentLogin />
      <Bottom />

      <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
        <Modal.Header closeButton className="header">
          <Modal.Title>ERRO AO REALIZAR O ACESSO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{error ? error : ''}</p>
          <p>Em caso de erro, por favor entrar em contato com Ana Paula.</p>
          <p>
            <b>Telefone: </b>(11) 94519-0299 <br />
            <b>E-mail: </b>anapaula@multimaxi.com.br
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="primary"
            onClick={() => hideModal()}
            className="button-close"
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginOkta;
