/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';

import { GET_LIST_MATERIAL } from 'services/Solicitacao';
import { TIPO_NEGOCIO_LIST } from 'services/TipoNegocio';
import {
  GET_LIST_MARCA,
} from 'services/ProdutoCategoria';

import './styles.scss';

const FilterArtes = (props) => {
  const { showLoader } = useContext(Context);

  const [tiposNegocios, setTiposNegocios] = useState([]);
  const [tiposNegociosSelecionadas, setTiposNegociosSelecionadas] = useState([]);

  const [marcas, setMarcas] = useState([]);
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
  const [marcasFiltradas, setMarcasFiltradas] = useState([]);

  const [materialComunicacao, setMaterialComunicacao] = useState([]);
  const [materialComunicacaoSelecionadas, setMaterialComunicacaoSelecionadas] =
    useState([]);

  const [solicitacaoId, setSolicitacaoId] = useState('');
  const [codigo, setCodigo] = useState('');

  // ============================================================================================================================
  // Effects
  useEffect(() => {
    showLoader(true);

    (async () => {
      const listaMarca = await GET_LIST_MARCA();
      setMarcas(listaMarca.value);

      const dataTipoNegocio = await TIPO_NEGOCIO_LIST();
      setTiposNegocios(dataTipoNegocio.value);

      const data = await GET_LIST_MATERIAL();
      setMaterialComunicacao(data.value);
    })();
    showLoader(false);
  }, []);

  useEffect(() => {
    let items = [];

    if (!tiposNegociosSelecionadas || tiposNegociosSelecionadas.length === 0) {
      items = marcas;
    } else {
      items = marcas.filter(
        (c) =>
        tiposNegociosSelecionadas.findIndex(
            (tipoNegocioId) => c.tipoNegociosId.includes(tipoNegocioId)
          ) !== -1
      );
    }

    setMarcasFiltradas(items);

    updateMarcasSelecionadas(items);

  }, [marcas, tiposNegociosSelecionadas]);

  // ============================================================================================================================
  // Event handlers
  function onChangeSolicitacaoId(event) {
    const { value } = event.target;
    setSolicitacaoId(value);
  }

  function onChangeCodigo(event) {
    const { value } = event.target;
    setCodigo(value);
  }

  const onChangeMaterialComunicacao = (event) => {
    let matComunicacao = materialComunicacaoSelecionadas;

    if (event.target.checked) {
      const item = matComunicacao.find((item) => item === event.target.value);

      if (!item) {
        matComunicacao.push(event.target.value);
      }
    } else {
      const item = matComunicacao.find((item) => item === event.target.value);
      const indexItem = matComunicacao.findIndex(
        (item) => item === event.target.value
      );

      if (item) {
        matComunicacao.splice(indexItem);
      }
    }

    setMaterialComunicacaoSelecionadas(matComunicacao);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeMarcas = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let marcas = marcasSelecionadas;
    let items = [];

    if ($element.checked) {
      const itemMarca = marcas.find((marca) => marca === elementValue);

      if (!itemMarca) {
        marcas.push(elementValue);
      }
      setMarcasSelecionadas([...marcas]);
    } else {
      let newMarcas = [];

      for (let i = 0; i < marcas.length; i++) {
        if (marcas[i] !== elementValue) {
          newMarcas.push(marcas[i]);
        }
      }

      setMarcasSelecionadas(newMarcas);
    }
  };

  const filterArtes = async () => {
    await props.filtrar(
      solicitacaoId,
      materialComunicacaoSelecionadas,
      marcasSelecionadas,
      tiposNegociosSelecionadas,
      codigo
    );
  };

  const clearArtes = async () => {
    setMaterialComunicacaoSelecionadas([]);
    setTiposNegociosSelecionadas([]);
    setMarcasSelecionadas([]);
    setSolicitacaoId('');
    setCodigo('');

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if (item.type === 'checkbox' && item.checked) {
        item.checked = false;
      }
    }

    await props.filtrar('', [], [], '');
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeTipoNegocios = (event) => {
    let tipos = tiposNegociosSelecionadas;
    if (event.target.checked) {
      const item = tipos.find(
        (item) => item === parseFloat(event.target.value)
      );

      if (!item) {
        tipos.push(parseFloat(event.target.value));
      }
    } else {
      const item = tipos.find(
        (item) => item === parseFloat(event.target.value)
      );
      const indexItem = tipos.findIndex(
        (item) => item === parseFloat(event.target.value)
      );

      if (item) {
        tipos.splice(indexItem);
      }
    }

    setTiposNegociosSelecionadas([...tipos]);
  };

  // ============================================================================================================================
  const updateMarcasSelecionadas = (categoriasFiltradas) => {
    const itensChecados = [].concat(
      ...marcasFiltradas.filter((x) => x.checked).map((x) => x.categoriaIds)
    );

    setMarcasSelecionadas(itensChecados);
  }

  // ============================================================================================================================
  // Render
  return (
    <div className="filter-artes">
      <div className="titulo">FILTROS</div>
      <div className="body">
        <div className="input">
          <label>Solicitação</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeSolicitacaoId}
            value={solicitacaoId}
          />
        </div>

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensNegocio">
            Tipo Negócio{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensNegocio">
            <div>
              {tiposNegocios.map((tipo) => (
                <div key={tipo.tipoNegocioId}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeTipoNegocios}
                      type="checkbox"
                      value={tipo.tipoNegocioId}
                    />{' '}
                    {tipo.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMarca">
            Categoria Produto{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensMarca">
            <div>
              {marcasFiltradas.map((marca) => {
                return (
                  <div key={marca.produtoCategoriaId}>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMarcas}
                        value={marca.produtoCategoriaId}
                        type="checkbox"
                      />{' '}
                      {marca.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMaterial">
            Material Comunicação{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensMaterial">
            <div>
              {materialComunicacao.map((material) => {
                return (
                  <div>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMaterialComunicacao}
                        type="checkbox"
                        value={material?.materialComunicacaoId}
                      />{' '}
                      {material.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="input">
          <label>Nome ou Código do Produto</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeCodigo}
            value={codigo}
          />
        </div>

        <hr className="default" />

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              <Button
                onClick={filterArtes}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar
              </Button>
              <Button
                onClick={clearArtes}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser} /> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </div >
  );
};

export default FilterArtes;
