/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Scroll from 'react-scrollbar';

import './styles.scss';

const TipoMaterialLista = (props) => {
  let model = props.model;
  let materiais = props.materiais;
  const todosMateriais = props.todosMateriais;

  const [solicitacoes, setSolicitacoes] = useState(model.solicitacoes);

  const updateSolicitacoes = () => {
    setSolicitacoes([...model.solicitacoes]);
  };

  useEffect(() => {
    setSolicitacoes(model.solicitacoes || []);
  }, [model]);

  const addRemoveItem = (event) => {
    if (event.target.checked) {
      const material = todosMateriais.find(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );

      const solicitacao = {
        materialComunicacaoId: material.materialComunicacaoId,
        materialComunicacao: material,
      };

      model.solicitacoes.push(solicitacao);
      updateSolicitacoes();
    } else {
      const indexItem = solicitacoes.findIndex(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      model.solicitacoes.splice(indexItem, 1);
      updateSolicitacoes(solicitacoes);
    }
  };

  const renderMateriais = () => {
    console.log(materiais);
    // if (!materiais.length) return null;

    return (
      <div className="row pb-4">
        <div className="d-flex align-items-stretch align-self-stretch">
          {materiais &&
            materiais.map((item) => {
              return (
                <div className="col-6">
                  <div className="d-block h-100">
                    <div className="title text-center text-uppercase	">
                      {item.nome}
                    </div>
                    <div className="content-tipo-material h-100">
                      <div className="container-fluid">
                        <div className="row">
                          {item.materiaisComunicacao
                            .sort((a, b) => a.nome.localeCompare(b.nome))
                            .map((material, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-lg-6 mx-auto pb-2"
                                >
                                  <div
                                    className={`lista-campanha-materiais ${
                                      solicitacoes.findIndex(
                                        (solicitacao) =>
                                          solicitacao.materialComunicacaoId ===
                                          material.materialComunicacaoId
                                      ) !== -1
                                        ? 'selecionado'
                                        : ''
                                    }`}
                                  >
                                    {/* <div>{material.nome} - t</div> */}
                                    <div className="body-card">
                                      <input
                                        type="checkbox"
                                        name=""
                                        id={`check-material-${material.materialComunicacaoId}`}
                                        value={material.materialComunicacaoId}
                                        onChange={addRemoveItem}
                                        checked={
                                          solicitacoes.findIndex(
                                            (solicitacao) =>
                                              solicitacao.materialComunicacaoId ===
                                              material.materialComunicacaoId
                                          ) !== -1
                                        }
                                      />

                                      <label
                                        htmlFor={`check-material-${material.materialComunicacaoId}`}
                                      >
                                        <img
                                          src={material.urlImagem}
                                          className="img-fluid"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return <>{renderMateriais()}</>;
};

export default TipoMaterialLista;
