import React from 'react';
import { Link } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import './styles.scss';
import TitlePages from 'components/_base/TitlePages';

function OpcoesMenu({ titulo, itens }) {
  return (
    <>
      <HeaderApp />
      <div className="wrapper-opcoes-menu">
        <TitlePages title={titulo} />
        <div className="d-flex justify-content-center flex-column align-items-center itens-menu">
          {itens.map((item) => {
            return (
              <Link to={item.link} key={item.id} className="button-content">
                <div>{item.texto}</div>
              </Link>
            );
          })}
        </div>
      </div>
      <Bottom />
    </>
  );
}

export default OpcoesMenu;
