/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import TitlePages from 'components/_base/TitlePages';

import { tabs, passos } from '../_constants';
import { TIPO_NEGOCIO_LIST } from 'services/TipoNegocio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const TipoNegocio = (props) => {
  let { setLoader, updateRascunho, model, renderErrors, setActiveTab } = props;
  const [tipoNegocio, setTipoNegocio] = useState([]);
  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoTipoNegocio.nro
  );

  useEffect(() => {
    (async () => {
      setLoader(true);

      const data = await TIPO_NEGOCIO_LIST();
      console.log(data)
      setTipoNegocio(data.value);

      Boolean(model.tipoNegocioId) && setActiveTab(tabs.tabCliente);

      setLoader(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoader(true);

      Boolean(model.tipoNegocioId) && setActiveTab(tabs.tabCliente);

      setLoader(false);
    })();
  }, [model.tipoNegocioId]);

  const onSubmit = (item) => {
    model.tipoNegocioId = item.tipoNegocioId;
    model.tipoNegocioNome = item.nome;
    // model.tipoNegocioId = null;
    // model.tipoNegocioNome = null;
    updateRascunho();
    setActiveTab(tabs.tabCliente);
  };

  return (
    <div className="wrapper-content-tipo-negocio">
      <TitlePages title="Escolha o tipo de negócio" />
      <div className="container">
        {renderErrors(errors)}

        <div className="content-tipo-negocio row">
          {tipoNegocio.length &&
            tipoNegocio.map((item) => (
              <div
                className="col-md-4"
                key={item.tipoNegocioId}
                onClick={() => onSubmit(item)}
              >
                <div className="negocio-content d-flex justify-content-center align-items-center">
                  <div className="negocio-nome">
                    <div>
                      <i className={'fa ' + item.icone}></i>
                    </div>
                    <div>{item.nome}</div>
                  </div>
                  <div className="negocio-hover text-center p-4">
                    <h4>{item.descricao}</h4>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row">
          <div className=" text-left">
            <Link to="/">
              <button className="btn btn-primary btn-navigation">
                <span>
                  <FaHome size={26} />
                </span>
                <span>
                  VOLTAR PARA A
                  <br /> PÁGINA INICIAL
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipoNegocio;
