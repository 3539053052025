import api from 'api';

const LOGIN = async (bodyData) => {
  const { data } = await api.post(`/login`, bodyData);
  return data;
};

const LOGIN_SSO = async (bodyData) => {
  const { data } = await api.post(`/sso/login-interno`, bodyData);
  return data;
};

const LOGOUT = async () => {
  const { data } = await api.get(`/logout`);
  return data;
};
const LOGIN_WITH_TOKEN = async (bodyData) => {
  const { data } = await api.post(`/login-with-token`, bodyData);
  return data;
};

export { LOGIN, LOGIN_SSO, LOGOUT, LOGIN_WITH_TOKEN };
