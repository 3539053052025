/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Extrato from './Extrato';
import ExtratoParcial from './ExtratoParcial';
import './styles.scss';
import api from '../../../api';

const ContentRelatorios = () => {

  const [urlTableauProdutividade, setUrlTableauProdutividade] = useState('');
  const [urlTableauVisaoGeral, setUrlTableauVisaoGeral] = useState('');

  useEffect(() => {
    // (async () => {
    //   let resultProdutividade = await api.get('/Tableau/get-url-produtividade');
    //   let resultVisaoGeral = await api.get('/Tableau/get-url-visaogeral');

    //   setUrlTableauProdutividade(resultProdutividade.data.value);
    //   setUrlTableauVisaoGeral(resultVisaoGeral.data.value);
    // })();
  }, []);

  return (
    <div className="content_relatorios">
      <h1 className="relatorio-title">RELATÓRIOS</h1>
      <Tabs defaultActiveKey="produtividade" id="uncontrolled-tab-example">
        <Tab eventKey="produtividade" title="Produtividade">
        <div className="text-center iframe-container-tableau">
           {urlTableauProdutividade.length > 0 && <iframe src={urlTableauProdutividade}></iframe>}
          </div>
        </Tab>
        <Tab eventKey="visaogeral" title="Visão Geral">
        <div className="text-center iframe-container-tableau">
           {urlTableauVisaoGeral.length > 0 && <iframe src={urlTableauVisaoGeral}></iframe>}
          </div>
        </Tab>
        <Tab eventKey="extrato" title="Extrato Mensal">
          <Extrato />
        </Tab>
        <Tab eventKey="extratoparcial" title="Extrato Parcial">
          <ExtratoParcial />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContentRelatorios;
