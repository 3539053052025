import api from 'api';

const PATH = '/TipoNegocio';

const TIPO_NEGOCIO_GET_BY_ID = async (id) => {
  const { data } = await api.get(`${PATH}/get/${id}`);
  return data;
};

const TIPO_NEGOCIO_LIST = async () => {
  const { data } = await api.get(`${PATH}/get-list`);
  return data;
};

const TIPO_NEGOCIO_SELECT_LIST = async () => {
  const { data } = await api.get(`${PATH}/get-select-list`);
  return data;
};

export { TIPO_NEGOCIO_GET_BY_ID, TIPO_NEGOCIO_LIST, TIPO_NEGOCIO_SELECT_LIST };
