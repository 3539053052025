import React from 'react';
import { Router } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { AuthProvider } from 'context/AuthContext';
import history from './history';
import Routes from './routes';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_DOMAIN,
  clientId: process.env.REACT_APP_OKTA_CLINT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

const MainRoutes = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(
      toRelativeUrl(originalUri || '/login', window.location.origin)
    );
  };
  return (
    <AuthProvider>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Router history={history}>
          <Routes />
        </Router>
      </Security>
    </AuthProvider>
  );
};

export default MainRoutes;
