import React from 'react';

import './styles.scss';
import loaderGif from 'assets/loader/Loading_2.gif';

const Loader = () => {
  return (
    <div className="loader-gif">
      <div>
        {/* <div className="align-self-center"> */}
        <img src={loaderGif} alt="loading..." />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Loader;
