import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const SolicitanteInvestimentoTabela = (props) => {
  const { data } = props;
  const titulo = 'INVESTIMENTO EPSON - SOLICITANTE';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const formatarValor = (valor) => {
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  const getBar = (porcentagem) => {
    const largura = porcentagem;
    return (
      <div className="celula-barra">
        <div
          className="barra"
          style={{
            width: `${largura * 0.85}%`,
            backgroundColor: '#898989',
          }}
        />
        <div className="barra-valor">{porcentagem}%</div>
      </div>
    );
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th
                className="fixed-header"
                style={{ width: '15%' }}
                aria-label="header"
                key="hn"
              >
                Solicitante
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hcd"
              >
                Custo Diagramação
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hct"
              >
                Custo Impressão + Frete
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="htc"
              >
                Total Gasto
              </th>
              <th
                className="fixed-header"
                style={{ width: '15%' }}
                aria-label="header"
                key="hn2"
              >
                Solicitante
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hts"
              >
                Total Solicitações
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hpa"
              >
                % Aprovada
              </th>
              <th
                className="fixed-header"
                style={{ width: '20%' }}
                aria-label="header"
                key="hvu"
              >
                % Verba Utilizada
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              const { nome } = item;
              return (
                <tr key={`r-${nome}`}>
                  <td key={`in-${nome}`}>{nome}</td>
                  <td key={`icd-${nome}`}>
                    {formatarValor(item.custoDiagramacao)}
                  </td>
                  <td key={`ict-${nome}`}>
                    {formatarValor(item.custoImpressao + item.custoFrete)}
                  </td>
                  <td key={`itc-${nome}`}>{formatarValor(item.totalCusto)}</td>
                  <td key={`in2-${nome}`}>{nome}</td>
                  <td key={`its-${nome}`}>{item.totalSolicitacoes}</td>
                  <td key={`ipa-${nome}`}>{item.porcentagemAprovada}%</td>
                  <td key={`ivu-${nome}`}>{getBar(item.porcentagemVerba)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SolicitanteInvestimentoTabela;
