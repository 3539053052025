/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaImage, FaList, FaPencilAlt } from 'react-icons/fa';

import { solicitacaoTabs, cadastroItemKeys } from '../../_constants';

import Layout from './Layout';
import Produtos from './Produtos';
import Dados from './Dados';

const Solicitacao = (props) => {
  const history = useHistory();
  const location = useLocation();

  let tipoNegocioId = props.tipoNegocioId;
  let pedidoDeCampanha = props.pedidoDeCampanha;
  let updateRascunho = props.updateRascunho ?? (() => {});
  let pedidoId = props.pedidoId;
  let loja = props.loja;

  let entregaCentroDistribuicao = props.entregaCentroDistribuicao;
  let entregaLojasParticipantes = props.entregaLojasParticipantes;
  let entregaOutro = props.entregaOutro;

  let entities = props.entities;
  let clienteLayouts = props.clienteLayouts;
  let solicitacao = props.solicitacao;
  let proximoMaterial = props.proximoMaterial;
  let renderErrors = props.renderErrors;
  let urlSolicitacao = props.urlSolicitacao;
  let locationIndexSolicitacaoTab = props.locationIndexSolicitacaoTab;

  let material = []
    .concat(
      ...entities.tipoMateriais.map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao
      )
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);

  let cadastroItens =
    entities.materiaisCadastroItens.find(
      (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId
    )?.cadastroItens || [];

  let initialTab = pedidoDeCampanha
    ? cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos
      ) === -1
      ? solicitacaoTabs.tabDados
      : solicitacaoTabs.tabProdutos
    : solicitacaoTabs.tabLayout;

  const currentTab =
    location.pathname.split('/')[locationIndexSolicitacaoTab] || initialTab;
  const [solicitacaoActiveTab, setSolicitacaoActiveTab] = useState(currentTab);

  solicitacao.isValid = () => {
    if (!solicitacao.isValidLayout || !solicitacao.isValidLayout()) {
      return false;
    }

    var cadastroItem = (cadastroItens || []).find(
      (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos
    );
    if (cadastroItem && cadastroItem.obrigatorio) {
      if (!solicitacao.isValidProdutos || !solicitacao.isValidProdutos())
        return false;
    }

    if (!solicitacao.isValidDados || !solicitacao.isValidDados()) {
      return false;
    }

    return true;
  };

  const changeTab = (solicitacaoTab) => {
    updateRascunho();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    history.push(`${urlSolicitacao}/${solicitacaoTab}`);
  };

  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[locationIndexSolicitacaoTab];
      if (tab) setSolicitacaoActiveTab(tab);
    });
  }, [history]);

  const salvar = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    updateRascunho();
    proximoMaterial();
  };

  const renderTabs = () => {
    if (!cadastroItens.length) return null;

    if (currentTab === solicitacaoTabs.tabLayout && pedidoDeCampanha)
      return null;

    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos
      ) === -1
    ) {
      if (currentTab === solicitacaoTabs.tabLayout)
        return (
          <Layout
            tipoNegocioId={tipoNegocioId}
            solicitacao={solicitacao}
            entities={entities}
            clienteLayouts={clienteLayouts}
            material={material}
            cadastroItens={cadastroItens}
            avancar={() => {
              changeTab(solicitacaoTabs.tabDados);
            }}
            renderErrors={renderErrors}
          />
        );

      if (currentTab === solicitacaoTabs.tabDados)
        return (
          <Dados
            pedidoId={pedidoId}
            loja={loja}
            entregaCentroDistribuicao={entregaCentroDistribuicao}
            entregaLojasParticipantes={entregaLojasParticipantes}
            entregaOutro={entregaOutro}
            entities={entities}
            solicitacao={solicitacao}
            material={material}
            cadastroItens={cadastroItens}
            avancar={() => {
              salvar();
            }}
            voltar={() => {
              changeTab(solicitacaoTabs.tabLayout);
            }}
            renderErrors={renderErrors}
            hideVoltar={pedidoDeCampanha}
          />
        );
    } else {
      if (currentTab === solicitacaoTabs.tabLayout)
        return (
          <Layout
            tipoNegocioId={tipoNegocioId}
            entities={entities}
            clienteLayouts={clienteLayouts}
            solicitacao={solicitacao}
            material={material}
            cadastroItens={cadastroItens}
            avancar={() => {
              changeTab(solicitacaoTabs.tabProdutos);
            }}
            renderErrors={renderErrors}
          />
        );

      if (currentTab === solicitacaoTabs.tabProdutos)
        return (
          <Produtos
            tipoNegocioId={tipoNegocioId}
            loja={loja}
            entities={entities}
            clienteLayouts={clienteLayouts}
            solicitacao={solicitacao}
            material={material}
            cadastroItens={cadastroItens}
            avancar={() => {
              changeTab(solicitacaoTabs.tabDados);
            }}
            voltar={() => {
              changeTab(solicitacaoTabs.tabLayout);
            }}
            renderErrors={renderErrors}
            hideVoltar={pedidoDeCampanha}
          />
        );

      if (currentTab === solicitacaoTabs.tabDados)
        return (
          <Dados
            pedidoId={pedidoId}
            loja={loja}
            entregaCentroDistribuicao={entregaCentroDistribuicao}
            entregaLojasParticipantes={entregaLojasParticipantes}
            entregaOutro={entregaOutro}
            entities={entities}
            solicitacao={solicitacao}
            material={material}
            cadastroItens={cadastroItens}
            publicoAlvo={entities.publicoAlvo}
            avancar={() => {
              salvar();
            }}
            voltar={() => {
              changeTab(solicitacaoTabs.tabProdutos);
            }}
            renderErrors={renderErrors}
          />
        );
    }

    return null;
  };

  const renderWizard = () => {
    if (!cadastroItens.length) return null;

    if (pedidoDeCampanha) return renderWizardCampanha();

    return renderWizardMateriais();
  };

  const renderWizardMateriais = () => {
    if (!cadastroItens.length) return null;

    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos
      ) === -1
    )
      return (
        <div className="solicitacao-wizard-form">
          <ul className="wizard-progressbar">
            <li
              className={
                currentTab === solicitacaoTabs.tabLayout ? 'active' : ''
              }
              style={{ width: '50%' }}
            >
              <div
                className="wizard-progressbar-container-icon"
                onClick={() => {
                  changeTab(solicitacaoTabs.tabLayout);
                }}
              >
                <FaImage size={22} />
              </div>
              <strong>Layout</strong>
            </li>

            <li
              className={
                currentTab === solicitacaoTabs.tabDados ? 'active' : ''
              }
              style={{ width: '50%' }}
            >
              <div
                className="wizard-progressbar-container-icon"
                onClick={() => {
                  changeTab(solicitacaoTabs.tabDados);
                }}
              >
                <FaPencilAlt size={22} />
              </div>
              <strong>Dados</strong>
            </li>
          </ul>
        </div>
      );

    return (
      <div className="solicitacao-wizard-form">
        <ul className="wizard-progressbar">
          <li
            className={currentTab === solicitacaoTabs.tabLayout ? 'active' : ''}
            style={{ width: '33%' }}
          >
            <div
              className="wizard-progressbar-container-icon"
              onClick={() => {
                changeTab(solicitacaoTabs.tabLayout);
              }}
            >
              <FaImage size={22} />
            </div>
            <strong>Layout</strong>
          </li>

          <li
            className={
              currentTab === solicitacaoTabs.tabProdutos ? 'active' : ''
            }
            style={{ width: '33%' }}
          >
            <div
              className="wizard-progressbar-container-icon"
              onClick={() => {
                changeTab(solicitacaoTabs.tabProdutos);
              }}
            >
              <FaList size={22} />
            </div>
            <strong>Produtos</strong>
          </li>

          <li
            className={currentTab === solicitacaoTabs.tabDados ? 'active' : ''}
            style={{ width: '33%' }}
          >
            <div
              className="wizard-progressbar-container-icon"
              onClick={() => {
                changeTab(solicitacaoTabs.tabDados);
              }}
            >
              <FaPencilAlt size={22} />
            </div>
            <strong>Dados</strong>
          </li>
        </ul>
      </div>
    );
  };

  const renderWizardCampanha = () => {
    if (!cadastroItens.length) return null;

    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Produtos
      ) === -1
    )
      return (
        <div className="solicitacao-wizard-form">
          <ul className="wizard-progressbar">
            <li
              className={
                currentTab === solicitacaoTabs.tabDados ? 'active' : ''
              }
              style={{ width: '100%' }}
            >
              <div
                className="wizard-progressbar-container-icon"
                onClick={() => {
                  changeTab(solicitacaoTabs.tabDados);
                }}
              >
                <FaPencilAlt size={22} />
              </div>
              <strong>Dados</strong>
            </li>
          </ul>
        </div>
      );

    return (
      <div className="solicitacao-wizard-form">
        <ul className="wizard-progressbar">
          <li
            className={
              currentTab === solicitacaoTabs.tabProdutos ? 'active' : ''
            }
            style={{ width: '50%' }}
          >
            <div
              className="wizard-progressbar-container-icon"
              onClick={() => {
                changeTab(solicitacaoTabs.tabProdutos);
              }}
            >
              <FaList size={22} />
            </div>
            <strong>Produtos</strong>
          </li>

          <li
            className={currentTab === solicitacaoTabs.tabDados ? 'active' : ''}
            style={{ width: '50%' }}
          >
            <div
              className="wizard-progressbar-container-icon"
              onClick={() => {
                changeTab(solicitacaoTabs.tabDados);
              }}
            >
              <FaPencilAlt size={22} />
            </div>
            <strong>Dados</strong>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className="wrapper-content-pedido-solicitacoes-solicitacao">
      <div className="content-pedido-solicitacoes-solicitacao">
        {renderWizard()}

        {renderTabs()}
      </div>
    </div>
  );
};

export default Solicitacao;
