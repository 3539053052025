import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import { formatarMoeda } from 'util/numberUtil';
import SemDados from '../SemDados';

const RegionalVerba = (props) => {
  const { data } = props;
  const titulo = 'VERBA UTILIZADA';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  // const dataTipos = ['Verba Utilizada', 'Verba Disponível'];

  // const itensNomes = data.map((item) => {
  //   return item.nome;
  // });

  const series = [
    {
      name: 'Verba Utilizada',
      data: data.map((item) => {
        return item.totalCusto;
      }),
    },
    // {
    //   name: 'Verba Disponível',
    //   data: data.map((item) => {
    //     return item.totalVerba;
    //   }),
    // },
  ];

  const maxUtilizado = Math.max(...series[0].data);
  // const maxDisponivel = Math.max(...series[1].data);
  const maxValue = Math.max(maxUtilizado);

  const options = {
    colors: ['#7daee3', '#18327f'],
    legend: {
      showForSingleSeries: true,
      // formatter: (seriesName) => {
      //   return [seriesName.toUpperCase()];
      // },
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      floating: false,
      offsetX: 0,
      offsetY: 0,
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 4,
    },
    xaxis: {
      categories: ['total'],
      // categories: [...itensNomes],
      show: true,
      labels: {
        show: false,
        offsetY: 0,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          const lines = lineBreaker(value, 7);
          return lines;
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      max: maxValue * 1.2,
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: 'can-solicitacoes',
      parentHeightOffset: 5,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value) => {
        return value === null ? formatarMoeda(0) : formatarMoeda(value);
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Verba Utilizada: <span id="data">R$${formatarMoeda(
                series[0][dataPointIndex]
              )}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable-horizontal">
        <Chart
          options={options}
          series={series}
          type="bar"
          height="250"
          style={{
            width: options.xaxis.categories.length * 100,
            minWidth: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default RegionalVerba;
