/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { FaAngleRight } from 'react-icons/fa';

import { passos } from '../../../_constants';
import { set } from 'date-fns';

const Layout = (props) => {
  let tipoNegocioId = props.tipoNegocioId;
  let entities = props.entities;
  let solicitacao = props.solicitacao;
  let avancar = props.avancar;
  let renderErrors = props.renderErrors;
  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoLayout.nro
  );

  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};

  let tipoNegocioLayouts =
    tipoNegocioId === null
      ? entities.clienteLayouts.layoutArtes
      : entities.clienteLayouts.layoutArtes?.filter((item) => {
          return item.tipoNegocioId == tipoNegocioId;
        });
  let layoutsAll = (tipoNegocioLayouts || []).filter(
    (layout) =>
      layout.layoutArteMateriaisComunicacao.findIndex(
        (lm) => lm.materialComunicacaoId === solicitacao.materialComunicacaoId
      ) !== -1
  );

  let selectListLayoutTipos = entities.layoutTipos.map((tipo) => {
    // console.log(tipo, 'dadsadas ');
    return {
      value: tipo.layoutArteTipoId,
      label: tipo.nome,
    };
  });

  // Variaveis de estado ---------------------------------------------------------------------------------------------------
  const [showProdutoSelector, setShowProdutoSelector] = useState(false);
  const [layoutTipoId, setLayoutTipoId] = useState(0);
  const [layoutArteId, setLayoutArteId] = useState(
    solicitacao.solicitacaoDados.layoutArteId || 0
  );
  const [layouts, setLayouts] = useState([]); //layoutsAll || []);
  const [produtos, setProdutos] = useState([]);
  const [selectListProdutos, setSelectListProdutos] = useState([]);
  const [produtoId, setProdutoId] = useState([]);
  const [selectListCategorias, setSelectListCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState(0);
  const [texto, setTexto] = useState('');

  // Efeitos ---------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const layoutId = solicitacao?.solicitacaoDados?.layoutArteId;
    setLayoutArteId(layoutId || 0);
    loadFilterData(layoutId);
  }, [solicitacao]);

  // Funcoes ---------------------------------------------------------------------------------------------------------------

  // Recarrega os dados do layout no filtro.
  const loadFilterData = (layoutId) => {
    if (!layoutId || layoutId <= 0) {
      return;
    }

    const layout = layoutsAll?.find((item) => item.layoutArteId === layoutId);
    layoutTipoOnChange(layout?.layoutArteTipoId);
    setLayoutTipoId(layout?.layoutArteTipoId);

    let tipo = entities.layoutTipos?.find((item) => {
      return item.layoutArteTipoId === layout?.layoutArteTipoId;
    });
    if (tipo?.tag != 'PROD') {
      return;
    }
    if (tipo?.tag != 'SKR') {
      console.log('SKR');
    }

    const produtos = getProdutosDosLayouts([layout]);
    if (produtos && produtos.length > 0) {
      const prod = produtos[0];
      loadCategoria(prod?.produtoCategoriaId);
      setCategoriaId(prod?.produtoCategoriaId);
    }
  };

  const getProdutosDosLayouts = (layoutList) => {
    let produtoIds = [];
    layoutList?.forEach((element) => {
      // Produtos Especificos
      element.layoutArteProdutosEspecificos?.forEach((produto) => {
        produtoIds.push(produto);
      });
      // Produtos Obrigatorios
      element.layoutArteProdutosObrigatorios?.forEach((produto) => {
        produtoIds.push(produto);
      });
    });

    // Remove os itens repetidos
    produtoIds = produtoIds.filter(
      (item, index) => produtoIds.indexOf(item) == index
    );

    // Monta alista de produtos
    let produtos = [];
    produtoIds?.forEach((item) => {
      const prod = entities.produtos?.find(
        (p) => p.produtoId == item.produtoId
      );
      if (prod) {
        // adiciona os dados de categoria
        prod.produtoCategoria = entities.produtoCategorias?.find(
          (cat) => cat.produtoCategoriaId == prod.produtoCategoriaId
        );
        produtos.push(prod);
      }
    });

    return produtos;
  };

  const getSelectListCategoria = (produtos) => {
    let selectList = [];
    entities.produtoCategorias?.forEach((cat) => {
      if (cat.produtoCategoriaSuperiorId == null) {
        let cats = [];
        produtos?.forEach((p) => {
          if (
            p.produtoCategoria?.produtoCategoriaSuperiorId ==
            cat.produtoCategoriaId
          ) {
            let c = {
              label: p.produtoCategoria.nome,
              value: p.produtoCategoriaId,
            };
            if (!cats.find((ct) => ct.value == c.value)) {
              cats.push(c);
            }
          }
        });
        cats = cats?.filter((item, index) => cats.indexOf(item) == index);

        let sup = {
          label: cat.nome,
          id: cat.produtoCategoriaId,
          options: cats,
        };
        selectList.push(sup);
      }
    });

    return selectList;
  };

  const getSelectListProdutos = (categoriaId) => {
    let prods = produtos?.filter((p) => p.produtoCategoriaId == categoriaId);
    prods = prods?.filter((p, index) => prods.indexOf(p) == index);

    let select = [];
    prods?.forEach((item) => {
      let p = { label: item.nome, value: item.produtoId };
      select.push(p);
    });

    return select;
  };

  const filterLayouts = (layoutTipoId) => {
    let layouts = [];

    if (!layoutTipoId || layoutTipoId <= 0) {
      layouts = []; //layoutsAll;
    } else {
      layouts = layoutsAll.filter((item) => {
        return item.layoutArteTipoId === layoutTipoId;
      });
    }

    return layouts;
  };

  const filterLayoutsAndProdutos = (layoutTipoId, categoriaId, produtoId) => {
    let layouts = [];
    console.log(layoutsAll, 'aqui');
    if (!layoutTipoId || layoutTipoId <= 0) {
      layouts = layoutsAll;
    } else {
      layouts = layoutsAll.filter((item) => {
        return item.layoutArteTipoId === layoutTipoId;
      });
    }

    if ((!produtoId || produtoId <= 0) && (!categoriaId || categoriaId <= 0)) {
      layouts = [];
    }

    if ((!produtoId || produtoId <= 0) && categoriaId) {
      let prodList = produtos?.filter(
        (p) => p.produtoCategoriaId == categoriaId
      );

      layouts = layouts?.filter((item) => {
        let ret = false;
        item.layoutArteProdutosEspecificos?.forEach((x) => {
          let p = prodList?.filter((y) => y.produtoId == x.produtoId);
          if (p && p.length > 0) {
            ret = true;
          }
        });
        item.layoutArteProdutosObrigatorios?.forEach((x) => {
          let p = prodList?.filter((y) => y.produtoId == x.produtoId);
          if (p && p.length > 0) {
            ret = true;
          }
        });
        return ret;
      });
    }

    if (produtoId && produtoId > 0) {
      let prodList = produtos?.filter((p) => p.produtoId == produtoId);

      layouts = layouts?.filter((item) => {
        let ret = false;
        item.layoutArteProdutosEspecificos?.forEach((x) => {
          let p = prodList?.filter((y) => y.produtoId == x.produtoId);
          if (p && p.length > 0) {
            ret = true;
          }
        });
        item.layoutArteProdutosObrigatorios?.forEach((x) => {
          let p = prodList?.filter((y) => y.produtoId == x.produtoId);
          if (p && p.length > 0) {
            ret = true;
          }
        });
        return ret;
      });
    }

    return layouts;
  };

  const filterLayoutsAndCategoria = (layoutTipoId, categoriaId) => {
    let layouts = [];

    if (!layoutTipoId || layoutTipoId <= 0) {
      layouts = layoutsAll;
    } else {
      layouts = layoutsAll.filter((item) => {
        return item.layoutArteTipoId === layoutTipoId;
      });
    }

    return layouts;
  };

  const produtoOnchange = (produtoId) => {
    setProdutoId(produtoId);
    let layouts = filterLayoutsAndProdutos();
    setLayouts(layouts);
  };

  const categoriaOnChange = (categoriaId) => {
    setCategoriaId(categoriaId);
    let layouts = filterLayoutsAndProdutos(layoutTipoId, categoriaId, -1);
    setLayouts(layouts);
    setProdutoId('');
    setSelectListProdutos(getSelectListProdutos(categoriaId));
  };

  const loadCategoria = (categoriaId) => {
    setCategoriaId(categoriaId);
    let layouts = filterLayoutsAndCategoria(layoutTipoId, categoriaId, -1);
    setLayouts(layouts);
    setProdutoId('');
    setSelectListProdutos(getSelectListProdutos(categoriaId));
  };

  const layoutTipoOnChange = (layoutTipoId) => {
    setLayoutTipoId(layoutTipoId);

    let tipo = entities.layoutTipos?.find((item) => {
      return item.layoutArteTipoId === layoutTipoId;
    });
    setTexto(tipo?.texto || '');

    solicitacao.solicitacaoDados.layoutArteTipoId = tipo?.layoutArteTipoId;
    solicitacao.solicitacaoDados.layoutArteTipoNome = tipo?.nome;

    if (tipo?.tag === 'SKR') {
      solicitacao.solicitacaoDados.possuiCTA =
        solicitacao.solicitacaoDados.possuiCTA || false;
      solicitacao.solicitacaoDados.textoCTA =
        solicitacao.solicitacaoDados.textoCTA || '';
      // solicitacao.solicitacaoDados.produtoFoco =
      //   solicitacao.solicitacaoDados.produtoFoco || '';
    } else {
      solicitacao.solicitacaoDados.possuiCTA = null;
      solicitacao.solicitacaoDados.textoCTA = null;
      // solicitacao.solicitacaoDados.produtoFoco = null;
    }

    if (tipo?.tag === 'PRODUTO ESPECIFICO') {
      // if (tipo?.tag === 'PROD') {
      setShowProdutoSelector(true);
      let layouts = filterLayouts(layoutTipoId);
      //setLayouts(layouts);
      let produtos = getProdutosDosLayouts(layouts);
      setProdutos(produtos);
      let select = getSelectListCategoria(produtos);
      setSelectListCategorias(select);

      //layouts = filterLayoutsAndProdutos(layoutTipoId);
      setLayouts([]);

      setCategoriaId('');
      setProdutoId('');
      setSelectListProdutos([]);
    } else {
      setShowProdutoSelector(false);
      let layouts = filterLayouts(layoutTipoId);
      const UnicoLayout = Boolean(layouts.length === 1);
      if (UnicoLayout) {
        solicitacao.solicitacaoDados.layoutArteId = layouts[0].layoutArteId;
        // solicitacao.solicitacaoDados.layoutArteId = layouts[0].layoutArteId;
        setLayoutArteId(solicitacao.solicitacaoDados.layoutArteId);
      }
      setLayouts(layouts);
      setCategoriaId('');
      setProdutoId('');
      setSelectListCategorias([]);
      setSelectListProdutos([]);
    }
  };

  // Filter render ---------------------------------------------------------------------------------------------------------
  const renderFilter = () => {
    return (
      <>
        <div className="row">
          {/* Selecao Tipo Layout ------------------------------------------------------------------------------------------ */}
          <div className="col-lg-4">
            <div className="mb-4">
              <label>Tipo de Arte</label>
              <Select
                options={selectListLayoutTipos}
                isClearable={true}
                onChange={(item) => {
                  layoutTipoOnChange(item?.value);
                }}
                value={selectListLayoutTipos.find(
                  (item) => item.value === layoutTipoId
                )}
              />
            </div>
          </div>
        </div>
        <h4 className="text-danger text-center pt-4">
          {!layoutTipoId &&
            'Selecione acima o tipo de Arte para visualizar os layouts'}
        </h4>

        {showProdutoSelector ? (
          <div className="row">
            {/* Selecao Categoria Produto ---------------------------------------------------------------------------------- */}
            <div className="col-lg-4">
              <div className="mb-4">
                <label>Categoria Produto</label>
                <Select
                  options={selectListCategorias}
                  isClearable={true}
                  onChange={(item) => {
                    categoriaOnChange(item?.value);
                  }}
                  // value={selectListCategorias.find(
                  //   (item) => (item.value || -1) === categoriaId
                  // )}
                  value={selectListCategorias
                    .find((item) =>
                      (item.options || []).find((x) => x.value === categoriaId)
                    )
                    ?.options?.find((y) => y.value === categoriaId)}
                />
              </div>
            </div>

            {/* Selecao Produto -------------------------------------------------------------------------------------------- */}
            <div className="col-lg-4">
              <div className="mb-4">
                <label>Produto</label>
                <Select
                  options={selectListProdutos}
                  isClearable={true}
                  onChange={(item) => {
                    produtoOnchange(item?.value);
                  }}
                  value={
                    selectListProdutos.find(
                      (item) => item.value === produtoId
                    ) || 0
                  }
                />
              </div>
            </div>
          </div>
        ) : null}
        {/* Texto explicativo ---------------------------------------------------------------------------------------------- */}
        {layouts && layouts.length > 0 ? (
          <div className="row">
            <div className="col-lg-12">
              <p>{texto}</p>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  // Layout render ---------------------------------------------------------------------------------------------------------
  const renderLayouts = () => {
    if (!layoutsAll.length) return null;

    return (
      <div className="row">
        {layouts
          .sort(function (a, b) {
            return a.descricao.localeCompare(b.descricao);
          })
          .map((layout, index) => {
            return (
              <div key={index} className="col-lg-4 mg-3">
                <label>
                  <input
                    type="radio"
                    checked={layout.layoutArteId === layoutArteId}
                    onChange={() => {
                      solicitacao.solicitacaoDados.layoutArteId =
                        layout.layoutArteId;
                      setLayoutArteId(
                        solicitacao.solicitacaoDados.layoutArteId
                      );
                    }}
                  />{' '}
                  {layout.descricao}
                  <img src={layout.urlImagem} className="img-fluid" />
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  // Main render -----------------------------
  return (
    <div>
      {renderErrors(errors)}

      {renderFilter()}

      {renderLayouts()}

      <div className="row mt-5">
        <div className="col-lg-12 d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
