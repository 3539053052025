import React from 'react';
import {
  // BrasilVerba,
  // CanalRank,
  // CanalVerbaMensal,
  MaterialRank,
  MaterialVerbaMensal,
  ProdutoRank,
  RegionalVerba,
  // RegionalVerbaMensal,
  // SolicitanteRegionalRankDinamico,
  // VerbaDisponivel,
  VisaoBrasil,
} from '../Graficos/gerencial';

const TabGerencial = React.memo((props) => {
  const { data } = props;
  return (
    <div className="container-tab">
      <div className="row-graficos">
        {/* <BrasilVerba data={data.verbaBrasil} /> */}
        <RegionalVerba data={data.verbaRegional} />
        <MaterialRank data={data.verbaMaterial} />
      </div>
      {/* <div className="row-graficos">
        <VerbaDisponivel data={data.verbaDisponivel} />
      </div> */}
      {/* <div className="row-graficos">
        <CanalRank data={data.verbaCanal} />
      </div> */}
      <div className="row-graficos">
        <ProdutoRank data={data.solicProduto} />
      </div>
      {/* <div className="row-graficos">
        <SolicitanteRegionalRankDinamico data={data.solicitanteRegional} />
      </div> */}
      <div className="row-graficos" style={{ maxHeight: '330px' }}>
        <VisaoBrasil data={data.visaoBrasil} />
      </div>
      {/* <div className="row-graficos">
        <RegionalVerbaMensal
          data={data.verbaRegionalMensal}
          dataLabels={data.labelsRegional}
        />
      </div> */}
      <div className="row-graficos">
        <MaterialVerbaMensal
          data={data.verbaMaterialMensal}
          dataLabels={data.labelsMaterial}
        />
      </div>
      {/* <div className="row-graficos">
        <CanalVerbaMensal
          data={data.verbaCanalMensal}
          dataLabels={data.labelsCanal}
        />
      </div> */}
    </div>
  );
});

export default TabGerencial;
