/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';
import { GET_SELECT_LIST_SOLICITANTE } from 'services/Usuario';
import { TIPO_NEGOCIO_SELECT_LIST } from 'services/TipoNegocio';

import { Context } from 'context/AuthContext';
import Select from 'react-select';

import DatePicker from 'components/_base/DatePicker';
import './styles.scss';

const Filter = (props) => {
  const model = props.model;
  const { getMaterialComunicacaoSelectList, getEtapaSelectList } =
    useContext(Context);
  const [materialList, setMaterialList] = useState([]);
  const [etapaList, setEtapaList] = useState([]);
  const [solicitanteList, setSolicitanteList] = useState([]);
  const [tipoNegocioList, setTipoNegocioList] = useState([]);

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
      setEtapaList(await getEtapaSelectList());

      const solicitante = await GET_SELECT_LIST_SOLICITANTE();
      setSolicitanteList(solicitante.value[0]);
      const tipoNegocio = await TIPO_NEGOCIO_SELECT_LIST();
      setTipoNegocioList(tipoNegocio.value[0])
      console.log(solicitante.value[0]);
      console.log(tipoNegocio.value[0]);

    })();
  }, []);

  function onChangeInput(event) {
    const { value, name } = event.target;

    props.setModel({
      ...model,
      [name]: value,
    });
  }

  async function onChangeWithName(value, name) {
    props.setModel({
      ...model,
      [name]: value,
    });
  }
  function onChangeMultiSelect(item, { name }) {
    const newValue =
      item && item.length !== null
        ? item.map((value) => {
            return parseInt(value.value, 10);
          })
        : '';

    props.setModel({
      ...model,
      [name]: newValue,
    });
  }
  return (
    <div className="filter-query">
      <form>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Nº da Solicitação
          </label>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="solicitacaoId"
              onChange={onChangeInput}
              value={model.solicitacaoId}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Loja
          </label>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="cliente"
              onChange={onChangeInput}
              value={model.cliente}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Material de Comunicação
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="materialComunicacaoIds"
              options={materialList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.materialComunicacaoIds &&
                model.materialComunicacaoIds.map((value) => {
                  let itemCompleto;
                  materialList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>
        {tipoNegocioList.options &&
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Tipo Negócio
          </label>
          <div className="col-md-9">
            <Select
              name="tipoNegocioId"
              options={tipoNegocioList.options}
              placeholder="Selecione..."
              onChange={(item, name) =>  props.setModel({...model,tipoNegocioId: item?.value,})}
              value={tipoNegocioList.options.find(
                (item) => item?.value === model.tipoNegocioId
              )}
            />
          </div>
        </FormGroup>}
        {solicitanteList.options &&
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Solicitante
          </label>
          <div className="col-md-9">
          <Select
              name="solicitanteId"
              options={solicitanteList.options}
              placeholder="Selecione..."
              onChange={(item, name) =>  props.setModel({...model,solicitanteId: item?.value,})}
              value={solicitanteList.options.find(
                (item) => item?.value === model.solicitanteId
              )}
            />
          </div>
        </FormGroup>}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Etapa Atual
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="etapaIds"
              options={etapaList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.etapaIds &&
                model.etapaIds.map((value) => {
                  let itemCompleto;
                  etapaList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Criado em
          </label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataInicio"
              maxDate={new Date()}
              selected={model.dataInicio}
              onChange={(item, name) => onChangeWithName(item, name)}
            />
          </div>
          <label className="col-md-1 col-form-label text-md-center">até</label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataFim"
              disabled={!model.dataInicio}
              minDate={model.dataInicio}
              maxDate={new Date()}
              selected={model.dataFim}
              onChange={onChangeWithName}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <div className="offset-md-3 col-md-9">
            <Button
              className="query-button"
              variant="primary"
              onClick={props.filter}
            >
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Filtrar
            </Button>
            <Button
              className="query-button"
              variant="outline-primary"
              onClick={props.clearfilter}
            >
              <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Limpar
            </Button>
          </div>
        </FormGroup>
      </form>
    </div>
  );
};

export default Filter;
